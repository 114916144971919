import React, { useEffect, useState } from "react";
import { Checkbox, Modal } from "antd";
import { VscRefresh } from "react-icons/vsc";
import { FaDownload } from "react-icons/fa";
import { downloadCode } from "../transformation-page/transformation.service";
import "./enhance-chevron.css"

const ruleList = [
    { id: 1, name: "Copyrights Rule", description: "Add a copy right header to the code" },
    { id: 2, name: "Service Layer Packaging", description: "Bundles the package by seggregating services business dtos files etc. Easy for maintenance in future" },
    { id: 3, name: "DB2 to Oracle", description: "Converts the legacy db2 calls to Oracle based db calls using SB jpa architecture" },
    { id: 4, name: "Batch as Rest", description: "Exposes the spring batch job as a rest service" },
    { id: 5, name: "JUnit Data Generation", description: "Inject code fragments at the start and end of each method to trace real data during execution and generate metadata, enabling JUnit generation based on actual runtime data."}
];

const EnhanceChevron = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRules, setSelectedRules] = useState<{ id: number; name: string }[]>([]);
    const [progress, setProgress] = useState<Record<string, number>>({});
    const [downloading, setDownloading] = useState<Record<string, boolean>>({});
    const [success, setSuccess] = useState(false);
    const [isProgress, setIsProgress] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const startProgress = () => {
        setIsProgress(true)
    }

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleCheckboxChange = (rule: { id: number; name: string }) => {
        if (selectedRules.some((r) => r.id === rule.id)) {
            setSelectedRules(selectedRules.filter((r) => r.id !== rule.id));
        } else {
            setSelectedRules([...selectedRules, rule]);
        }
    };

    const handleRemoveRule = (ruleId: number) => {
        setSelectedRules(selectedRules.filter((rule) => rule.id !== ruleId));
    };

    const onDownloadCode = async (name: string) => {
        try {
            setDownloading(prev => ({ ...prev, [name]: true }));
            await downloadCode(name);
        } finally {
            setDownloading(prev => ({ ...prev, [name]: false }));
        }
    };

    useEffect(() => {
        const intervalIds: Record<string, NodeJS.Timeout> = {};
        if (isProgress) {
            const intervalTime = 100
            intervalIds['CFBOOKSJ'] = setInterval(() => {
                setProgress(prevProgress => {
                    const newProgress = { ...prevProgress };
                    newProgress['CFBOOKSJ'] = (newProgress['CFBOOKSJ'] || 0) + 1;
                    if (newProgress['CFBOOKSJ'] >= 100) {
                        clearInterval(intervalIds['CFBOOKSJ']);
                        newProgress['CFBOOKSJ'] = 100;
                        setSuccess(true);
                    }
                    return newProgress;
                });
            }, intervalTime);
        }
        return () => {
            Object.values(intervalIds).forEach(clearInterval);
        };
    }, [isProgress]);

    return (
        <div>
            <div
                className="rounded-lg border bg-card text-card-foreground shadow-sm"
                data-v0-t="card"
            >
                <div className="flex flex-col space-y-4 p-6 bg-[#0a61dc] text-white">
                    <div className='flex flex-row'>
                        <h2 className="text-2xl font-bold text-white">Jobs ready for Enhancements</h2>
                    </div>
                    <p className="text-sm text-white">Enhancements allow you to apply customizable rules to jobs. Once applied, the code will be re-packaged to incorporate the latest updates, and you will have the option to download the updated code.</p>
                </div>
                <hr />
                <div className="p-6">
                    <div className="relative w-full overflow-auto">
                        <table className="w-full caption-bottom text-sm">
                            <thead className="[&amp;_tr]:border-b ">
                                <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                    <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[100px]">
                                        Job Name
                                    </th>
                                    <th className="h-12 px-4 text-center align-middle font-medium text-muted-foreground w-[250px]">
                                        Transformation Status
                                    </th>
                                    <th className="h-12 px-4 text-right align-middle font-medium text-muted-foreground w-[250px]">
                                        Enhance
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="[&amp;_tr:last-child]:border-0">
                                <React.Fragment>
                                    <tr
                                        className={`border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted`}
                                    >
                                        <td className="p-4 align-middle text-left font-medium" rowSpan={2}>
                                            CFBOOKSJ
                                        </td>
                                        <td className="p-4 align-middle w-[250px] text-center text-green-400 font-semibold">
                                            SUCCESS
                                        </td>
                                        <td className="p-4 align-middle w-[250px] text-right">
                                            <button className="bg-cta text-white p-2 rounded-md" onClick={showModal} >Select Rules</button>
                                        </td>
                                    </tr>
                                    {selectedRules.length > 0 && (
                                        <tr className="bg-white">
                                            <td className="p-4" colSpan={2}>
                                                <div className="p-4 border bg-white border-blue-300 shadow-md rounded-md">
                                                    {isProgress && (
                                                    <div>
                                                        <div className="progress-bar">
                                                            <div className="step">
                                                                <div className="step-label">
                                                                    Applying Rules
                                                                </div>
                                                                <div
                                                                    className="progress"
                                                                    style={{
                                                                        width: progress['CFBOOKSJ']
                                                                            ? `${Math.min(progress['CFBOOKSJ'] * 2, 100)}%`
                                                                            : 0,
                                                                    }}
                                                                ></div>
                                                            </div>
                                                            <div className="step">
                                                                <div className="step-label">
                                                                    Packaging
                                                                </div>
                                                                <div
                                                                    className="progress"
                                                                    style={{
                                                                        width: progress['CFBOOKSJ']
                                                                            ? `${Math.min(Math.max(progress['CFBOOKSJ'] - 50, 0) * 2, 100)}%`
                                                                            : 0,
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                        <div className="progress-info pt-2">
                                                            <span>
                                                                {progress['CFBOOKSJ'] || 0}% Complete
                                                            </span>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {success && (
                                                                    <button
                                                                        disabled={downloading['CFBOOKSJ']}
                                                                        className="flex items-center px-3 py-1 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600 transition-colors duration-200"
                                                                        onClick={() => onDownloadCode('CFBOOKSJ')}
                                                                    >
                                                                        <FaDownload className="mr-2" />
                                                                        {downloading['CFBOOKSJ']
                                                                            ? 'Downloading...'
                                                                            : 'Download Code'}
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>)}
                                                    {!isProgress && !success && (
                                                        <div>
                                                            <div className="text-gray-500 text-sm font-semibold mb-2 text-center">Regenerate code using the selected rules</div>
                                                            <div className={`flex items-center justify-between animate-fadeIn`}>
                                                                <div className="flex flex-col w-1/2">
                                                                    <div className="flex flex-wrap gap-4 mt-4">
                                                                        {selectedRules.map((rule) => (
                                                                            <div key={rule.id} className="flex items-center bg-gray-100 py-1 px-2 rounded-md shadow-md">
                                                                                <span className="text-sm">{rule.name}</span>
                                                                                <button
                                                                                    onClick={() => handleRemoveRule(rule.id)}
                                                                                    className="ml-2 text-red-500 font-bold"
                                                                                >
                                                                                    &times;
                                                                                </button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <button className="bg-green-700 text-white px-4 py-2 rounded-md font-medium mt-4 text-sm flex items-center gap-2" onClick={startProgress}>
                                                                    <VscRefresh /> Repackage Code
                                                                </button>
                                                            </div>
                                                        </div>)}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal
                title={
                    <div className="text-center font-semibold text-[20px]">
                        Select Rules
                    </div>
                }
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <hr className="my-4 border-gray-300" />
                {ruleList.map((rule) => (
                    <div key={rule.id} className="mb-4 flex items-start">
                        <Checkbox
                            checked={selectedRules.some((r) => r.id === rule.id)}
                            onChange={() => handleCheckboxChange(rule)}
                            className="mr-2 mt-1"
                        />
                        <div>
                            <span className="font-medium text-gray-800 text-[18px]">{rule.name}</span>
                            <p className="text-gray-500 text-[17px] mt-1">
                                {rule.description}
                            </p>
                        </div>
                    </div>
                ))}
            </Modal>


        </div >
    )
}
export default EnhanceChevron;